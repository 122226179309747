
<script>
export default {
    props: {

        title: {
            type: String,
            default: ""
        },

        isLoading : {
            type: Boolean,
            default: false
        },

        width : {
            type : Number,
            default : 3
        },

        height: {
            type: Number,
            default: 3
        }
    }
}
</script>
            
<template>
      <div v-if="isLoading">
        <div class="row">
            <div class="col text-center" >
                <div class="spinner-border text-primary"  :style="`width: ${width}rem; height: ${height}rem;`" role="status">
                </div>
                <p> <span>{{title}}</span></p>
            </div>
        </div>
    </div>
</template>

