<script>


export default {
    components: {
    },

    mounted() {

        console.log("A Mounted");
    },

    props: {
        messageObj: {
            type: Object,
            required: true
        },
    },

    watch: {
        messageObj: {
            handler: function (newVal) {
                // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                this.linkText = newVal.elementText;
                this.cssSelector = newVal.cssSelector;
                this.htmlAttributes = newVal.htmlAttributes;
            },
            deep: true
        }
    },

    data() {
        return {
            linkText: this.messageObj.elementText,
            cssSelector: this.messageObj.cssSelector,
            htmlAttributes: this.messageObj.htmlAttributes,
            hasMadeChanges: false
        }
    },

    methods: {
        onValueChanged(e) {
            console.log('defaultTag', this.linkText, e);
            this.$emit('textChanged', this.linkText);
            this.hasMadeChanges = true;
        },

        onAttributeChanged(attribute, value) {
            console.log(attribute, ':', value);
            this.$emit('attributeChanged', { attribute: attribute, value: value });
        },


        onCssSelectorChanged() {
            this.$emit('cssSelectorChanged', this.linkText);
        },

        onDeleteChanges() {
            console.log("on delete changes");
            this.$emit('resetText');
        }
    }
}
</script>
        
<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label>
                        <strong>CSS Selector</strong>
                    </label>
                    <div>
                        <input type="text" class="form-control" v-model="cssSelector" v-on:keyup="onCssSelectorChanged">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="form-group">
                    <label>
                        <strong>Text</strong>
                    </label>

                    <!--start handling multiple values -->

                    <div>
                        <input type="text" class="form-control" v-model="linkText" v-on:keyup="onValueChanged">
                    </div>
                </div>
            </div>
        </div>
        <div class="row " v-if="hasMadeChanges">
            <div class="col">
                <div class="d-grid d-md-flex justify-content-md-end">
                    <button type="button" class="btn btn-link" @click="onDeleteChanges">Delete changes</button>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col">
                <h5>HTML attributes</h5>
            </div>
        </div>

        <div class="row" v-for="item in htmlAttributes" :key="item.key">
            <div class="col">
                <div class="form-group">
                    <label>
                        {{ item.key }}
                    </label>
                    <div>
                        <input type="text" class="form-control" v-model="item.value"
                            v-on:keyup="onAttributeChanged(item.key, item.value)">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>