<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
//import webpageEditor from "@/components/webpageEditor/editor";
import defaultTag from "@/components/webpageEditor/tagForms/defaultTag";
import imgTag from "@/components/webpageEditor/tagForms/imgTag";
import api from '@/services/secureHttps';
import Spinner from "@/components/spinner";

export default {
    components: {
        Layout,
        PageHeader,
        defaultTag,
        imgTag,
        Spinner
    },

    props: {
        pageId: {
            type: Number,
            required: true
        },
        url: {
            type: String,
            required: true
        }
    },

    mounted() {
        this.myIframe = document.getElementById('iframe', this.pageId);
        var componentScope = this;
        this.myIframe.addEventListener("load", function () {
            console.log('iframe lodaed');
            componentScope.init();

        });

        //https://localhost:7041/Assets/DevScripts/IframeTest/testGenScript.html
        // "https://localhost:7041/Assets/DevScripts/IframeTest/testGenScript.html?_ensureNoCache=" + encodeURIComponent(this.uuidv4());

            console.log('editPage url:', this.url);
        if (typeof this.url === 'undefined') {
            return;
        }

        let uniqueUrl = this.ensureUrlIsNotCached(this.url);
        console.log(uniqueUrl);
        this.myIframe.src = uniqueUrl;
    },


    data() {
        return {
            title: "Web Page Editor",
            items: [],
            myIframe: null,
            successfullySaved: false,
            elementChanges: {
                attributes: [],
                htmlAttributes: []
            },
            isLoading : false
        }
    },


    methods: {
        init() {
            var componentScope = this
            this.bindEvent(window, 'message', function (e) {
                console.log('parent received:', e.data);

                componentScope.elementChanges.elementText = e.data.text;
                componentScope.elementChanges.currentTag = e.data.tagName;
                componentScope.elementChanges.cssSelector = e.data.cssSelector
                console.log("changes", componentScope.elementChanges);
            });

            var messageObj = {
                type: "init"
            };

            this.sendMessage(messageObj);
        },

        // addEventListener support for IE8
        bindEvent(element, eventName, eventHandler) {
            if (element.addEventListener) {
                element.addEventListener(eventName, eventHandler, false);
            } else if (element.attachEvent) {
                element.attachEvent('on' + eventName, eventHandler);
            }
        },


        // Send a message to the child iframe
        sendMessage(msg) {
            console.log("sending msg", msg);
            // Make sure you are sending a string, and to stringify JSON
            this.myIframe.contentWindow.postMessage(msg, '*');
        },

        onTextChanged(newText) {
            var messageObj = {
                type: "textChanged",
                newValue: newText
            };

            this.elementChanges.elementText = newText;

            console.log("edit page onTextChanged", messageObj);

            this.sendMessage(messageObj);
        },

        onAttributeChanged(attributeObj) {
            console.log('attribute', attributeObj);

            var messageObj = {
                type: "attributeChanged",
                newValue: attributeObj
            };

            var existingAttr = this.elementChanges.attributes.find(x => x.attribute === attributeObj.attribute);
            console.log(existingAttr);
            if (typeof existingAttr !== 'undefined') {
                existingAttr.value = attributeObj.value;
            } else {
                this.elementChanges.attributes.push(attributeObj);
            }


            this.sendMessage(messageObj);
        },

        onResetText() {
            var messageObj = {
                type: "resetText"
            };

            this.sendMessage(messageObj);
        },

        applyChanges() {
            this.successfullySaved = false;
            this.isLoading = true;
            console.log('editPage: here', this.messageObj);
            var requestObj = {
                cssSelector: this.elementChanges.cssSelector,
                elementText: this.elementChanges.elementText,
                tagName: this.tagName,
                elementAttributes: this.elementChanges.attributes
            };

            console.log('requestObj', requestObj);

            api.AddWebPageEdit(this.pageId, requestObj)
                .then(r => {
                    console.log(r);
                    this.successfullySaved = true;
                }).finally(() => {
                    this.isLoading = false;
                })
        },
        uuidv4() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        },
        ensureUrlIsNotCached(url) {
            if (typeof url === 'undefined') {
                return;
            }

            // iframe can sometimes only ever use cached page. Adding a parameter with unique ID should ensure URL is always unique
            var arr = this.url.split('?');
            if (arr.length > 1 && arr[1] !== '') {
                url = url + "&___ensureNoCache=" + encodeURIComponent(this.uuidv4());
            } else {
                url = url + "?___ensureNoCache=" + encodeURIComponent(this.uuidv4());
            }

            return url;
        }
    }
}
</script>
        
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="container-fluid h-100">
            <div class="row h-100">
                <div class="col">
                    <div>
                        <iframe id="iframe" style="width:100%; height:78vh" sandbox="allow-scripts"></iframe>
                    </div>
                </div>
                <div class="col-2">

                    <div v-if="elementChanges.currentTag == 'IMG'">
                        <imgTag :messageObj="elementChanges" @textChanged="onTextChanged"></imgTag>
                    </div>
                    <div v-else-if="elementChanges.currentTag !== ''">
                        <defaultTag :messageObj="elementChanges" @textChanged="onTextChanged" @resetText="onResetText"
                            @attributeChanged="onAttributeChanged">
                        </defaultTag>
                    </div>

                    <div v-if="elementChanges.currentTag !== ''" class="mt-4">
                        <button class="primary" @click="applyChanges">Apply change</button>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <div v-show="successfullySaved" class="alert alert-success" role="alert">
                                Successfully saved.
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col">
                            <Spinner :isLoading="isLoading"></Spinner>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>