<script>


export default {
    components: {
    },

    mounted() {

        console.log("IMG Mounted");

        var altAttr = this.messageObj.htmlAttributes.filter(x => x.key == "alt")
        if (typeof altAttr !== 'undefined' && altAttr.length > 1) {
            this.altText = altAttr[0].value;
        }
    },

    props: {
        messageObj: {
            type: Object,
            required: true
        },
    },

    watch: {
        messageObj: {
            handler: function (newVal) {
               // console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                this.linkText = newVal.elementText;
                this.cssSelector = newVal.cssSelector;
                this.htmlAttributes = newVal.htmlAttributes;
            },
            deep: true
        }
    },


    computed: {
        filteredAttributes: function() {
            return this.htmlAttributes.filter( x=> x.key !== 'alt');
        }
    },

    data() {
        return {
            linkText: this.messageObj.elementText,
            cssSelector: this.messageObj.cssSelector,
            htmlAttributes: this.messageObj.htmlAttributes,
            altText: ""
        }
    },

    methods: {
        onValueChanged() {
            this.$emit('textChanged', this.linkText);
        },

        onAttributeChanged(model) {
            console.log(model);
            this.$emit('attributeChanged', this.linkText);
        },

        onCssSelectorChanged() {
            this.$emit('cssSelectorChanged', this.linkText);
        }
    }
}
</script>
        
<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="form-group col">
                    <label>
                        <strong>CSS Selector</strong>
                    </label>
                    <div>
                        <input type="text" class="form-control" v-model="cssSelector" v-on:keyup="onCssSelectorChanged">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="form-group col">
                    <label>
                        <strong>Text</strong>
                    </label>

                    <!--start handling multiple values -->
                    <div>
                        <input type="text" class="form-control" :disabled="true" value="This element does not have text">
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <div class="form-group col">
                    <label>
                        <strong>Alternate Text</strong>
                    </label>
                    <p>Alternate text can help search engines understand your image bestter.</p>

                    <!--start handling multiple values -->
                    <div>
                        <input type="text" class="form-control" v-model="altText" >
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col">
                <h5>HTML attributes</h5>
            </div>
        </div>

        <div class="row" v-for="item in filteredAttributes" :key="item.key">
            <div class="col">
                <div class="form-group col">
                    <label>
                        {{ item.key }}
                    </label>
                    <div>
                        <input type="text" class="form-control" v-model="item.value">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>